export default {
  colors: {
    // bulma new branding
    // primary: '#144C8D',
    // link: '#144C8D',
    // info: '#144C8D',
    // success: '#4c8d14',
    // warning: '#FFA355',
    // danger: '#D7281E',
    // bulma old branding
    primary: '#891519',
    link: '#891519',
    info: '#151989',
    success: '#198915',
    warning: '#894B15',
    danger: '#CB1F25',
    // others
    white: '#FFF',
  },
  sizes: {
    // bulma
    phone: 500,
    tablet: 750,
    desktop: 900,
    widescreen: 1050,
    fullhd: 1050,
  },
}
